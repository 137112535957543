<template>
   <section class="main-content templates-container">
    <div class="columns large-9">
      <div class="heading-step-2">{{ $t("templates.view") }}</div>
      <div class="sidebar preview-content">
        <div class="preview-template">
          <div class="header-preview">{{ $t("templates.chat") }}</div>
          <div class="preview-text">
            <img
              v-if="isValidMultimediaType"
              :src="require('@/assets/images/placeholder.png')"
            />
            <img v-if="showPreview"
              :src="imageURL"
            />
            {{ parseText }}
            <span v-if="footer">{{ footer }}</span>
          </div>
          <div class="time-text">
            {{ today }}
          </div>
        </div>
        <div class="input-labels">
          <div class="heading-labels">{{ $t("templates.params") }}</div>
          <hcc-input
            v-if="isImageTemplate"
            :label="$t('templates.url')"
            v-model.trim="imageURL"
            requiredInput
            customClass="input"
          />
          <div v-for="(param, i) in numParams" :key="i">
            <div class="param-container">
              <div class="params">
                <hcc-input
                  :label="$t('templates.param') + (i + 1)"
                  :placeholder="'{{' + (i + 1) + '}}'"
                  v-model="params[i]"
                  requiredInput
                  customClass="input"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="preview_information" v-show="hasInformation">
        {{ $t('campaigns.data-bank.templates.information') }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    template: {
      required: true,
      type: Object,
    },
    numParams: Number,
    footer: String,
    hasInformation: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      params: [],
      imageURL: null,
      formattedLabels: [],
    };
  },
  components: {
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
  },
  computed: {
    isImageTemplate() {
      return ['tImage', 'video', 'document', 'image'].includes(this.template.type);
    },
    parseText() {
      let { text } = this.template;
      this.params.forEach((param, index) => {
        const re = `{{${index + 1}}}`;
        text = text.replace(re, `${param}`);
      });
      return text;
    },
    today() {
      return this.$moment(new Date())
        .format('hh:mm A');
    },
    isValidMultimediaType() {
      return this.isImageTemplate && this.template.type !== 'image';
    },
    showPreview() {
      return ['tImage', 'image'].includes(this.template.type) && this.imageURL;
    },
  },
  watch: {
    parseText(value) {
      this.$emit('change', value);
    },
    params(value) {
      this.$emit('params', value);
    },
    imageURL(value) {
      this.$emit('multimedia', value);
    },
  },
};
</script>

<style scoped lang="scss">
  @import "~styles/views/_sendTemplates.scss";
  ::v-deep .preview-content {
    padding: 30px !important;
    display: flex;
  }

  .preview_information {
    font-size: 12px;
    text-align: center;
    background-color: $red-light;
    padding: 5px;
  }
</style>
